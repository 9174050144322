import { register } from '@shopify/theme-sections';
import Swiper, { Navigation, Pagination, EffectFade, Lazy } from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade, Lazy]);


register('hero-slider', {
    onLoad() {
            // eslint-disable-next-line no-unused-vars
            const slider = new Swiper(this.container.querySelector('[data-hero-slider]'), {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                // Disable preloading of all images
                preloadImages: false,
                // Enable lazy loading
                lazy: true,
                watchSlidesVisibility: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
        
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
});
